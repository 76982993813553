import './App.css';
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import myImage from './arrow.png';
import myHomeImage from './house.png';





const socket = io(process.env.REACT_APP_BACKEND_SERVER, { withCredentials: true });



function App() {
  
  const [inputValue, setInputValue] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [responseState, setResponseState] = useState(false);
  const [formattedText, setFormattedText] = useState('');
  const [sourceNameVar, setSourceNameVar] = useState("");
  const [sourceLinkVar, setSourceLinkVar] = useState("");
  const [searched, setSearched] = useState(false);
  
  
  

  

  useEffect(() => {
    function processText(text) {
      // Regular expression to capture 'Source:' and 'Source_Link:' followed by any characters until an asterisk
      const sourcePattern = /(Source|Source_Link):\s*([^*]+)\*/g;
      
      let newText = text.replace(sourcePattern, (match, prefix, capturedText) => {
        if (prefix === 'Source') {
          setSourceNameVar(capturedText); // Assuming setSourceNameVar is a function to set state for source
        } else if (prefix === 'Source_Link') {
          setSourceLinkVar(capturedText); // Assuming setSourceLinkVar is a function to set state for source link
        }
        return '\n \n \n '; // Replacing with three new lines
      });
    
      setResponseState(true);
      setFormattedText(newText);
    }
    socket.on('gptResponse', data => {
      processText(data);
      

      
      
      
      
      
    });

    socket.on('error', errorMessage => {
      console.error('Error:', errorMessage);
    });

    // Clean up the effect
    return () => socket.off('gptResponse').off('error');
  }, []);

  const sendPrompt = () => {
    socket.emit('gptRequest', { inputValue });
  };

  useEffect(() => {
    // Attach the event listener
    window.addEventListener('keydown', handleKeyPress);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });
  
  
 
  
  
  
  

  
  function handleInputChange(event) {
    setInputValue('  ' + (event.target.value).trimStart());
    
  }

  function resetInput(event) {
    setInputValue(' ');
  }

  

  



  
 
  
    
  
  

  function handleButton() {
    setSearched(true);
    sendPrompt();
    resetInput();
    


    
    
    
    
    
    
    

  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleButton();
      // Add any action here
    }
  }
  
 

  

  


  


  const Home_Button = (event) => {
    setSearched(false);
    const e = event;
    setResponseState(false);
    setShowSearchBar(true);
    resetInput(e);
    console.log("all functions ran");
    
  }



  
    
  
    

  

  

  

  
  
  
  
  
  
  return (
    <div className="App">
      
    
      
      
        <h1 onClick={Home_Button}>VELOX AI</h1>
       
        {showSearchBar ? 
        <div className="search-bar-container" style={{gridArea: searched ? "sources" : "main",alignItems:  searched ? "start" : "center" , alignSelf: searched ? "start" : "center", marginTop: searched ? "35px" : "none"}} > {/*style={{gridArea: searched ? "sources" : "main"}} */}
          <div className="search-wrap"> {/*, marginTop : searched ? "-755px" : null*/} {/*style={{gridArea: searched ? "sources" : "main"}}*/}
            <input className="search-bar" type="text" value={inputValue} onChange={handleInputChange}/>
            <button onClick={handleButton} className="search-button">{<img className="button-image" src={myImage} alt="arrow"/>}</button>
          
          

          
          </div>  
        </div> : null}
        

        
        
        { responseState ? <img className="home-button" src={myHomeImage} alt="home logo" onClick={Home_Button}/> : null }
        { responseState ? <div className="source-container"><pre className="source-one" onClick={() => {window.open(`${sourceLinkVar}`);}}>{"Source: "} {sourceNameVar}<img src={`https://www.google.com/s2/favicons?sz=128&domain=${sourceNameVar}`} alt="Source Icon" /> </pre></div> : null }
        { responseState ? <pre className="test-output">{formattedText}</pre> : null }
         
        
        {/*responseState ? 
        <div>
          <img className="home-button" src={myHomeImage} alt="home logo" onClick={Home_Button}/>
          <pre className="source-one" onClick={() => {window.open(`${sourceLinkVar}`, '_blank');}}>{"Source: "} {sourceNameVar}<img src={`https://www.google.com/s2/favicons?sz=128&domain=${sourceNameVar}`} alt="Source Icon" /> </pre> 
          <pre className="test-output">{formattedText}{<TestTag/>}</pre>
        </div> : null*/}
        {/*testing*/}
        
     
      
      
      

        
      
    </div>
  );
}

export default App;









